// Copyright 2024 * Redcliff Capital Ltd. 

export default {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
