// Copyright 2024 * Redcliff Capital Ltd.

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";
import ShowCaseTrading from "pages/Presentation/components/ShowCaseTrading"
import ShowCaseDifferent from "pages/Presentation/components/ShowCaseDifferent"
import ShowCasePersonal from "pages/Presentation/components/ShowCasePersonal"

// Data
import data from "pages/Presentation/sections/data/designBlocksData";

// Icons
import Icon from "@mui/material/Icon";

function DesignBlocks() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ image, name, count, route, pro }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
              <Link to={pro ? "/" : route}>
                <ExampleCard image={image} name={name} count={count} pro={pro} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: "auto", mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="warning"
            badgeContent="The old way isn't working ye?"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            FACT:
          </MKTypography>
          <MKTypography variant="body1" color="text">
            You will NEVER succeed using Retail Trading strategies all the time such as technical
            analysis.
          </MKTypography>
        </Grid>
      </Container>

      <ShowCaseTrading/>

      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="warning"
            badgeContent="NO waste of time."
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            FACT:
          </MKTypography>
          <MKTypography variant="body1" color="text">
          These training methods we used to train our own 
          traders are tested and proved with a *93% successful rate.
          <br/>
          <br/>
          The training we provide suites almost all range of investors. 
          You will learn from day trading to long-term portfolio building, 
          to hedging & risk management and more advanced.
          </MKTypography>
          <br/>
          <MKButton size="large" color="error" my={5} py={5}>
            <Icon>stars</Icon> Book A Consultation Now!
          </MKButton>
        </Grid>
      </Container>

      <ShowCaseDifferent/>
      <ShowCasePersonal/>
    </MKBox>
  );
}

export default DesignBlocks;
