import MKBox from "components/MKBox"
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

export default function ShowCasePersonal() {
    return (
        <MKBox
        display="flex"
        alignItems="center"
        borderRadius="xl"
        my={6}
        py={20}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.8)
            )}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
            <MKTypography variant="body1" color="black" opacity={0.8} mb={2} 
                sx={{ fontFamily: 'Monospace' }}
            >
            I started my career in insurance credit risk management before transitioning to trading. 
            With over 13 years of experience, I have traded stocks, commodities, CFDs, options, 
            and other derivatives. Currently, I am the owner of a boutique hedge fund where 
            I collaborate with other experienced traders. My investment career has brought me 
            significant success and has generated wealth for many of my traders. I firmly 
            believe that anyone can succeed with the right start and opportunity.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    )
}