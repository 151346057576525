// Copyright 2024 * Redcliff Capital Ltd. 

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
