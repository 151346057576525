import { Grid, List, ListItem, ListItemText, Collapse, ListSubheader } from "@mui/material"
import MKBox from "components/MKBox";
// import coursesData from "./data/coursesData.json"
// import coursesData1 from "./data/coursesData1.json"
import bgCourse from "../../../../assets/images/man-stock0.jpg"
import MKTypography from "components/MKTypography";


export default function Courses(courseData) {
    const open = true;

    function courseGen(data) {
        const renderData = data.chapters.map(chapter => (
            <List alignItems="top" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                <ListItemText primary={chapter.chapterName}/>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {chapter.courses.map(course => {
                        return (
                            <ListItem>
                                <ListItemText primary={course.courseName} secondary={course.time} />
                            </ListItem>
                        )
                    })}
                    </List>
                </Collapse>
            </List>
        ));

        return (
            <Grid container alignItems="top">
                <Grid item sm={12} md={12} lg={12} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 0, md: 0 }, border: 'solid orange 5px',
                            borderBottomStyle: 'none' }}>
                    <MKTypography 
                        variant="h3"
                        fontWeight="bold"
                        color="white"
                        textAlign="left"
                        // px={{ xs: 6, lg: 12 }}
                        // mt={1} 
                        // borderRadius="10px" 
                        sx={{
                            // backgroundImage: `url(${bgCourse})`,
                            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                            `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.8)
                            )}, url(${bgCourse})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "grid",
                            placeItems: "left",
                            spacing: 2,
                            // m: 2
                          }}
                    >
                        {data.title}
                        <br />
                        {data.sub}
                    </MKTypography>
                </Grid>
                <Grid item sm={12} md={12} lg={12} sx={{ ml: { xs: 0, lg: 0 }, mb: { xs: 12, md: 0 }, border: 'solid orange 5px', borderTopStyle: 'none' }}>
                    <MKBox
                    component="section"
                    display="flex"
                    // alignItems="left"
                    // borderRadius="xl"
                    // my={6}
                    // py={20}
                    sx={{ m: 3}}
                    >
                        {renderData}
                    </MKBox>
                </Grid>
                
            </Grid>
    
      
        )
    }

    return (
        courseGen(courseData)
    )


    
}