
// This is the home page for now

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
// import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";
import Courses from "./components/Courses";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";
// import ShowCaseTrading from "pages/Presentation/components/ShowCaseTrading"

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import bgImage1 from "assets/images/city-profile.jpg";

// Icons
import Icon from "@mui/material/Icon";

// Data
import courseData from "./components/Courses/data/coursesData.json"
import courseData1 from "./components/Courses/data/coursesData1.json"
console.log(courseData);

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.redcliffcapital.co.uk",
          label: "log in",
          color: "info",
        }}
        brand="Yolovest."
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container backgroundColor="white">
          <Grid container item xs={12} lg={8} justifyContent="left" mx="auto">
            <MKTypography
              variant="h3"
              fontWeight="bold"
              color="white"
              textAlign="left"
              px={{ xs: 6, lg: 12 }}
              mt={1}
              backgroundImage={bgImage1}
              backgroundColor="white"
              style={{ backgroundColor: "blue", alignSelf: "flex-start", opacity: "0.8" }}
            >
              Learn To TRADE PROFITABLY By Getting education from institutional traders.
              <br />
            </MKTypography>
            <MKButton size="large" color="error">
              <Icon>stars</Icon> Book A Consultation Now!
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          // backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Counters /> */}
        {/* <Information /> */}
        <DesignBlocks />
        <MKBox pb={5}>
          <Courses {...courseData}/>
        </MKBox>
        <MKBox>
          <Courses {...courseData1}/>
        </MKBox>
        
        <Newsletter />
        {/* <Testimonials />
        <Download /> */}
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best value trading courses
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://blog.redcliffcapital.co.uk"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
