// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Pictures
import bloomberg0 from "../../../../assets/images/trading-showcases/bloomberg-0.jpg"
import bloomberg1 from "../../../../assets/images/trading-showcases/bloomberg-1.png"
import bloomberg2 from "../../../../assets/images/trading-showcases/bloomberg-2.png"

function BuiltByDevelopers() {
  // const bgImage =
  //   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={6}
      py={6}
    >
      <Grid container spacing={0}>
        {/* <MKTypography xs={12} lg={12} sx={12} md={12} variant="h2" fontWeight="bold" color="black">
          FACT:
        </MKTypography> */}

        <Grid container item xs={4} lg={4} sx={4} md={4} spacing={2}
          style={{
            backgroundImage: `url(${bloomberg0})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            height: '600px',
            border: 'solid orange 10px',
            // backgroundPosition: "center",
          }}
        ></Grid>
        <Grid container item xs={4} lg={4} sx={4} md={4} spacing={2}
          style={{
            backgroundImage: `url(${bloomberg1})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            height: '600px',
            border: 'solid orange 10px',
            // backgroundPosition: "center",
          }}
        ></Grid>
        <Grid container item xs={4} lg={4} sx={4} md={4} spacing={2}
          style={{
            backgroundImage: `url(${bloomberg2})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            height: '600px',
            border: 'solid orange 10px',
            // backgroundPosition: "center",
          }}
        ></Grid>
      </Grid>
    </MKBox>
  );
}

export default BuiltByDevelopers;
