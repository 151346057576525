// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import group from "assets/images/group.jpg";

function Newsletter() {
  return (
    <MKBox component="section" pt={6} my={6}>
      {/* <Container> */}
      <Grid container alignItems="center">
        <Grid item sm={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
          <MKTypography variant="h3">Don&apos;t forget to join our free community</MKTypography>
          <MKTypography variant="body2" color="text" mb={3} mt={2} sx={{ fontSize: 20 }}>
            <ul>
              <li>Take advantage the complete FREE membership</li>
              <li>6 month free access to our market watch newsletter</li>
              <li>Join Lv.0 trading group with thousands of traders from all around world</li>
              <li>Study plan Consultation</li>
            </ul>
          </MKTypography>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <MKInput type="email" label="Email Here..." fullWidth />
            </Grid>
            <Grid item xs={4}>
              <MKButton variant="gradient" color="warning" sx={{ height: "100%" }}>
                Subscribe
              </MKButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
          <MKBox position="relative">
            <MKBox component="img" src={group} alt="group" width="60%" />
          </MKBox>
        </Grid>
      </Grid>
      {/* </Container> */}
    </MKBox>
  );
}

export default Newsletter;
