// Copyright 2024 * Redcliff Capital Ltd. 

// Material Kit 2 React base styles
import colors from "assets/theme/base/colors";

const { text } = colors;

export default {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};
